import { template as template_3e9b4e2522e94d8299a40fa2a187bbc0 } from "@ember/template-compiler";
const FKLabel = template_3e9b4e2522e94d8299a40fa2a187bbc0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
