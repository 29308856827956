import { template as template_0ba419e6f6af4325b3e31fd055811126 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0ba419e6f6af4325b3e31fd055811126(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
