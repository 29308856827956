import { template as template_441fe8b845ea40fbb14d00f1ace30b7c } from "@ember/template-compiler";
const WelcomeHeader = template_441fe8b845ea40fbb14d00f1ace30b7c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
