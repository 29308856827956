import { template as template_ec1827a9b534488fab9e4aa10e3a3085 } from "@ember/template-compiler";
const FKText = template_ec1827a9b534488fab9e4aa10e3a3085(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
