import { template as template_13b05d57aebc47f88567df623fa57889 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_13b05d57aebc47f88567df623fa57889(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
