import { template as template_26c72d2d41a94a4ea597f7350f7d9c26 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import { escapeExpression } from "discourse/lib/utilities";
export default class FKControlTextarea extends Component {
    static controlType = "textarea";
    @action
    handleInput(event) {
        this.args.field.set(event.target.value);
    }
    get style() {
        if (!this.args.height) {
            return;
        }
        return htmlSafe(`height: ${escapeExpression(this.args.height)}px`);
    }
    static{
        template_26c72d2d41a94a4ea597f7350f7d9c26(`
    <textarea
      class="form-kit__control-textarea"
      style={{this.style}}
      disabled={{@field.disabled}}
      ...attributes
      {{on "input" this.handleInput}}
    >{{@field.value}}</textarea>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
