import { template as template_297c19d0d1ce41b9bdbc33a369052ea4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_297c19d0d1ce41b9bdbc33a369052ea4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
